import React from 'react';
import { Outlet } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  topMargin: {
    marginTop: '10vh',
    minHeight: '90vh',
    height: '100%'
  }
}));

const NoAuthWrapper = ({ hasHeader = false }) => {
  const classes = useStyles();

  return (
    <div className={hasHeader ? classes.topMargin : null}>
      <Outlet />
    </div>
  );
};

export { NoAuthWrapper };
